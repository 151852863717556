import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import ShortDate, { LongDate } from "./ShortDate";
import { colors } from "../../../../../vars/palette";
import { WmkLink } from "wmk-link";

const Styled = {
  EventExcerpt: styled(Col)`
    margin-bottom: 0.75rem;
    a&:hover {
      text-decoration: none;
    }
  `,
  EventTitleLink: styled(WmkLink)`
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      transition: all 0.3s ease;
      * {
        color: ${colors.hex("primary")};
      }
    }
  `,
  EventTitle: styled.h5`
    margin: 0;
    color: ${colors.hex("secondary")};
  `
};

const EventExcerpt = ({
  startDate,
  endDate,
  link,
  children
}: {
  startDate: Date;
  endDate?: Date;
  link: string;
  children: React.ReactNode;
}) => {
  return (
    <Styled.EventExcerpt xs={6}>
      <Row>
        <Col xs="auto">
          <ShortDate date={startDate} />
        </Col>
        <Col>
          <Row className="flex-column">
            <Col>
              {link && link !== "" ? (
                <Styled.EventTitleLink to={link} target="_blank">
                  <Styled.EventTitle>{children}</Styled.EventTitle>
                </Styled.EventTitleLink>
              ) : (
                <Styled.EventTitle>{children}</Styled.EventTitle>
              )}
            </Col>
            <LongDate date={startDate} />
          </Row>
        </Col>
      </Row>
    </Styled.EventExcerpt>
  );
};

export default EventExcerpt;
