import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../ui/colors";

const Styled = {
  LeftColumn: styled(Col)`
    -webkit-flex: initial;
    & > .row {
      padding: 1rem;
    }
  `,
  RightColumn: styled(Col)`
    & > .row {
      padding: 0 1rem;
    }
    background-color: ${colors.sky.getHex()};
  `
};

const TwoColumnLayout = ({
  Main,
  Sidebar,
  fluid
}: {
  Main: React.FunctionComponent;
  Sidebar: React.FunctionComponent;
  fluid?: boolean;
}) => {
  return (
    <Container>
      <Row style={{ margin: fluid ? 0 : "3vh" }}>
        <Styled.LeftColumn xs={12} md={7} lg={8}>
          <Main />
        </Styled.LeftColumn>
        <Styled.RightColumn xs={12} md={5} lg={4}>
          <Sidebar />
        </Styled.RightColumn>
      </Row>
    </Container>
  );
};

export default TwoColumnLayout;
