import { RichText } from "wmk-rich-text";
import { NewsExcerptFields } from "../node/fragments/NodeNewsExcerptFields";
import { NewsFields } from "../node/fragments/NodeNewsFields";
import { Tag } from "wmk-tag";
import { Img } from "wmk-image";

export interface PdfFile {
  title: string;
  url: string;
}

export class NewsPost {
  title: string;
  slug: string;
  image?: Img;
  date: Date;
  content: RichText;
  tags: Tag[];
  link: string;
  pdf?: PdfFile;
  path: string;
  constructor(node: NewsFields | NewsExcerptFields) {
    const _node = { ...node };
    const _image = _node.featImage ? new Img(_node.featImage) : undefined;
    if (_image) {
      _image.addCrop("thumb", _node.featImage.thumb);
      if ("ogImage" in _node.featImage) {
        _image.addCrop("single", _node.featImage.single);
        _image.addCrop("ogImage", _node.featImage.ogImage);
        _image.addCrop("twitterImage", _node.featImage.twitterImage);
      } else {
        _image.addCrop("sqaure", _node.featImage.square);
        _image.addCrop("excerpt", _node.featImage.excerpt);
      }
    }
    this.title = _node.title;
    this.slug = _node.slug;
    this.image = _image;
    this.date = new Date(_node.date);
    this.content = new RichText(_node.article);
    this.tags = Array.isArray(_node.tags)
      ? _node.tags.map((t) => new Tag(t))
      : [];
    this.link = _node.link;
    this.pdf =
      "pdf" in _node && _node.pdf?.file?.contentType.match(/pdf/)
        ? { title: _node.pdf.title, url: _node.pdf.file.url }
        : undefined;
    this.path = `/news/${node.slug}`;
  }
}

export class NewsPosts {
  list: NewsPost[];
  length: number;
  constructor(edges: { node: NewsFields | NewsExcerptFields }[]) {
    const posts = Array.isArray(edges)
      ? edges.map((e) => new NewsPost(e.node))
      : [];
    this.list = posts;
    this.length = posts.length;
  }
}
