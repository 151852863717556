import React from "react";
import { useRef, useEffect } from "react";

const PowrFeed = ({ id }: { id: string }) => {
  const scriptRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const current = scriptRef.current;
    const powrScript = document.createElement("script");
    powrScript.type = "text/javascript";
    powrScript.src = "https://www.powr.io/powr.js?platform=embed";
    current.appendChild(powrScript);
  });
  return <div ref={scriptRef} className="powr-social-feed" id={id} />;
};

export default PowrFeed;
