import styled from "styled-components";
import { colors } from "../../../ui/colors";

export const StyledSidebar = styled.div`
  padding: 1rem;
  a {
    color: ${colors.blue.getHex()};
    padding: 0 0 0 0.5rem;
    position: relative;
    bottom: 0;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover {
      color: ${colors.cyan.getHex()};
      transition: all 0.3s ease;
    }
  }
`;
