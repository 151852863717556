import React from "react";
import NewsHero from "./NewsHero";
import NewsAndEvents from "../NewsAndEvents";
import { NewsPost } from "../../../../../classes/News";
import { Img } from "wmk-image";
import { Event } from "../../../../../classes/Events";

const HomeNewsExcertps = ({
  posts,
  header,
  events
}: {
  posts: NewsPost[];
  header: { title: string; image: Img };
  events: Event[];
}) => {
  return (
    <>
      <NewsHero title={header.title} image={header.image} />
      <NewsAndEvents posts={posts} events={events} title={header.title} />
    </>
  );
};

export default HomeNewsExcertps;
