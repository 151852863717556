import React from "react";
import ModernFeed from "./NewsFeed/ModernFeed";
import DivBot from "../../../flexible/blocks/BlockBody/Divider/DivBot";
import EventsFeed from "./EventsFeed/EventsFeed";
import { NewsPost } from "../../../../classes/News";
import { Event } from "../../../../classes/Events";

const MainColumn = ({
  posts,
  events,
  title
}: {
  posts: NewsPost[];
  events: Event[];
  title: string;
}) => {
  return (
    <>
      <ModernFeed posts={posts} />
      <DivBot pos="Center" />
      <EventsFeed events={events} />
    </>
  );
};

export default MainColumn;
