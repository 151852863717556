import React from "react";
import PostTag from "./PostTag";
import styled from "styled-components";
import { Col } from "react-bootstrap";
import { colors } from "../../../../../vars/palette";
import { Tag } from "wmk-tag";

const StyledTags = styled(Col)`
  font-size: 0.7rem;
  font-weight: bold;
  a {
    color: ${colors.hex("accent")};
    text-decoration: none;
    &:hover {
      color: ${colors.hex("secondary")};
    }
  }
`;

const ModernTags = ({ tags, all }: { tags: Tag[]; all?: boolean }) => {
  return tags.length ? (
    all ? (
      <StyledTags>
        {tags.map((tag, i) => {
          const sep = i < tags.length - 1 ? ", " : "";
          return <PostTag key={tag.slug + i}>{tag.name + sep}</PostTag>;
        })}
      </StyledTags>
    ) : (
      <StyledTags>
        <PostTag>{tags[0].name}</PostTag>
      </StyledTags>
    )
  ) : null;
};

export default ModernTags;
