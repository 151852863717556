import React from "react";
import { WmkSeo } from "wmk-seo";
import { graphql } from "gatsby";
import { HomePageFields } from "../node/fragments/NodePageFields";
import { GlobalFields } from "../node/fragments/NodeGlobalFields";
import { ContentfulImageQuery, Img } from "wmk-image";
import VidHeroAndLinks from "../components/routes/Home/VidHeroAndLinks/VideoHeroAndLinks";
import HomeNewsExcerpts from "../components/routes/Home/NewsAndEvents/v1/HomeNewsExcerpts";
import { Page } from "../classes/Page";
import { NewsExcerptFields } from "../node/fragments/NodeNewsExcerptFields";
import { NewsPosts } from "../classes/News";
import { EventsFields } from "../node/fragments/NodeEventsFields";
import { Events } from "../classes/Events";

const IndexPage = ({ data }: { data: IndexQuery }) => {
  const page = new Page(data.page);
  const news = new NewsPosts(data.recentNews.edges);
  const events = new Events(data.recentEvents.edges);

  return (
    <>
      <WmkSeo.Meta
        title={page.titleBar}
        //description={desc}
        slug="/"
        siteTitle={data.meta.title}
        ogImageUrl={data.options.ogImage.file.url}
        twitterImageUrl={data.options.twitterImage.file.url}
        baseUrl={data.meta.baseUrl}
      />
      <VidHeroAndLinks slides={data.options.homeSlidesContent} />
      <HomeNewsExcerpts
        posts={news.list}
        events={events.list}
        header={{
          title: data.newsOptions.title,
          image: new Img(data.newsOptions.image)
        }}
      />
    </>
  );
};

export default IndexPage;

interface IndexGlobalQuery extends GlobalFields {
  ogImage: ContentfulImageQuery;
  twitterImage: ContentfulImageQuery;
}

interface IndexQuery {
  page: HomePageFields;
  meta: { title: string; baseUrl: string };
  options: IndexGlobalQuery;
  newsOptions: {
    title: string;
    image: ContentfulImageQuery;
  };
  recentNews: { edges: { node: NewsExcerptFields }[] };
  recentEvents: { edges: { node: EventsFields }[] };
}

export const query = graphql`
  {
    page: contentfulPages(slug: { eq: "home" }) {
      ...NodePageFields
      ...NodeHomePageFields
    }
    meta: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
      title: siteTitle
      baseUrl: siteUrl
    }
    recentNews: allContentfulNews(
      limit: 3
      sort: { fields: [date, title], order: DESC }
      filter: { visibility: { in: "UTRWD" } }
    ) {
      edges {
        node {
          ...NodeNewsExcerptFields
        }
      }
    }
    recentEvents: allContentfulEvents(
      sort: { fields: [date, title], order: ASC }
    ) {
      edges {
        node {
          ...NodeEventsFields
        }
      }
    }
    newsOptions: contentfulGlobal(title: { eq: "Homepage: News & Events" }) {
      title: addressLine1
      image: homeHeroThumbnail {
        title
        gatsbyImageData
        file {
          url
          contentType
        }
      }
    }
    options: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
      ...NodeGlobalFields
      ogImage: homeHeroThumbnail {
        gatsbyImageData(width: 1200, height: 630, quality: 80)
        file {
          url
          contentType
        }
      }
      twitterImage: homeHeroThumbnail {
        gatsbyImageData(width: 876, height: 438, quality: 80)
        file {
          url
          contentType
        }
      }
    }
  }
`;
