import React from "react";
import TwoColumnLayout from "../../../layout/TwoColumnLayout";
import MainColumn from "./MainColumn";
import Sidebar from "./Sidebar";
import { NewsPost } from "../../../../classes/News";
import { Event } from "../../../../classes/Events";

const NewsAndEvents = ({
  posts,
  events,
  title
}: {
  posts: NewsPost[];
  events: Event[];
  title: string;
}) => {
  return (
    <TwoColumnLayout
      fluid
      Main={() => <MainColumn posts={posts} events={events} title={title} />}
      Sidebar={Sidebar}
    />
  );
};

export default NewsAndEvents;
