import React from "react";
import { Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import { IconQuickLinksFields } from "../../../../../node/fragments/NodeIconQuickLinksFields";
import { WmkLink } from "wmk-link";
import { constructPath } from "../../../../Blocks/BlockHash";
import { colors } from "../../../../ui/colors";

const circleSize = "10rem";

const CircleWrap = styled(Col)`
  &.circle-col:not(:last-of-type):after {
    content: "";
    width: 3px;
    height: 70px;
    background: ${colors.cyan.getHex()};
    position: absolute;
    right: -3px;
    top: 20%;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  &.circle-col {
    transform: scale(1);
    transition: transform 0.5s ease;
    a&:hover {
      transform: scale(1.2);
      transition: transform 0.5s ease;
    }
    .icon-wrap {
      width: ${circleSize};
      height: ${circleSize};
      clip-path: circle(50%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      position: relative;
      overflow: hidden !important;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        display: block;
      }
      &:hover {
        overflow: hidden !important;
        .water-fill {
          top: -10%;
          transition: all 1s ease;
          overflow: hidden !important;
        }
      }
      .water-fill {
        position: absolute;
        z-index: 0;
        top: 110%;
        left: 0%;
        width: 100%;
        height: 110%;
        opacity: 0.5;
        transition: all 1s ease;
        overflow: hidden !important;
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
    h5 {
      margin-top: 2rem;
      text-transform: uppercase;
      padding: 0 4rem;
      text-align: center;
      @media only screen and (max-width: 767px) {
        margin: 0.5rem 0 1rem 0;
      }
    }
  }
`;

const QLCircles = () => {
  const data: CircleQLQuery = useStaticQuery(query);
  const circles = data.menu.circles; //data.circles.edges.map((edge) => edge.node);
  const overlay = data.overlay;
  return (
    <>
      {circles &&
        circles.map((circle, i) => {
          const linkSlug = constructPath(circle.linkPage);
          const { icon, title } = circle;
          const fluid = new Img(icon);
          return (
            <CircleWrap sm={4} className="circle-col" key={title + i}>
              <WmkLink to={linkSlug}>
                <div className="icon-wrap">
                  {fluid && <WmkImage image={fluid} />}
                  <div className="water-fill">
                    {overlay && <WmkImage image={new Img(overlay)} />}
                  </div>
                </div>
              </WmkLink>
              <h5>{title}</h5>
            </CircleWrap>
          );
        })}
    </>
  );
};

export default QLCircles;

interface CircleQLQuery {
  menu: { circles: IconQuickLinksFields[] };
  overlay: ContentfulImageQuery;
}

const query = graphql`
  {
    menu: contentfulMenuContainer(title: { eq: "Quick Links: Circles" }) {
      circles: menuItems {
        __typename
        ... on ContentfulIconQuickLinks {
          ...NodeIconQuickLinksFields
        }
      }
    }
    overlay: contentfulAsset(title: { eq: "Water Overlay" }) {
      title
      gatsbyImageData
      file {
        url
        contentType
      }
    }
  }
`;
