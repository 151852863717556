import React from "react";

const DivBotLine = ({width}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="4"
      viewBox={`0 0 ${width} 4`}
      className="div-bot-line"
    >
      <path
        d={`M10934-8515.3h${width}`}
        transform="translate(-10932 8517.302)"
        fill="none"
        stroke="#a7d6f0"
        strokeLinecap="round"
        strokeWidth="4"
      />
    </svg>
  );
};

export default DivBotLine;
