import React from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const StyledHeading = styled.h4`
  font-weight: bold;
  color: ${colors.hex("primary")};
  padding-bottom: 0.25rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid #cccccc;
`;

const WidgetHeading = ({
  children,
  style
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return <StyledHeading style={style}>{children}</StyledHeading>;
};

export default WidgetHeading;
