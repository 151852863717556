import React from "react";
import { colors } from "../../../../../vars/palette";

const WaveBottom = ({
  width,
  height = 60
}: {
  width: number;
  height?: number;
}) => {
  //const respHeight = height / 1440 * width
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={{ transform: "scale(1)" }}
      // viewBox="0 0 1440 44.062"
    >
      <path
        // id="Path_680"
        // data-name="Path 680"
        d={`M0,186.523H${width}V218s-118.369-41-350.6-12.078-417.484,36.625-711.121,0S0,218,0,218Z`}
        transform="translate(0 -186.523)"
        fill={colors.hex("secondary")}
      />
    </svg>
  );
};

export default WaveBottom;
