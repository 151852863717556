import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import ModernExcerpt from "./ModernExcerpt";
import styled from "styled-components";
import WidgetHeading from "../../../../common/typography/WidgetHeading";
import MoreLink from "../../../../common/MoreLink";
import { NewsPost } from "../../../../../classes/News";

export const StyledCard = styled(Card)`
  padding: 2vh;
  margin-top: 4vh;
`;

const ModernFeed = ({
  posts,
  title
}: //archive
{
  posts: NewsPost[];
  title?: string;
}) => {
  return (
    <Row className="flex-column" style={{ marginBottom: "2vh" }}>
      <WidgetHeading>{title ? title : "News"}</WidgetHeading>
      <Row>
        {posts.map((post, i) => {
          const { title, date, tags, image, content, path } = post;
          return (
            <Col key={title + i} xs={12} sm={12} md={i === 0 ? 12 : 6}>
              <ModernExcerpt
                title={title}
                date={date}
                tags={tags}
                path={path}
                image={image}
                content={content}
                big={i === 0 ? true : undefined}
              />
            </Col>
          );
        })}
      </Row>
      <MoreLink to="/newsroom" style={{ padding: "3vh 0 0 0" }}>
        View More
      </MoreLink>
    </Row>
  );
};

export default ModernFeed;
