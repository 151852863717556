import React from "react";
import { colors } from "../../../../ui/colors";

const WaveTop = ({
  width,
  height = 45
}: {
  width: number;
  height?: number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={{ transform: "scale(1)" }}
      //viewBox="0 0 1442 46.021"
    >
      <path
        //id="Path_1907"
        //data-name="Path 1907"
        d={`M3241.141,315.1s-118.534-41-351.087-12.078-418.064,36.625-712.109,0-378.8,12.078-378.8,12.078v20.3h${width}Z`}
        transform="translate(-1799.141 -289.381)"
        fill={colors.cyan.getHex()}
      />
    </svg>
  );
};

export default WaveTop;
