import React from "react";
import styled from "styled-components";
import QuickLinks from "./QuickLinks/QuickLinks";
import HeroSlider from "./HeroSlider";
import {
  HomeSlideContentFields,
  LrhSlideContentFields
} from "../../../../node/fragments/NodeGlobalFields";

const Wrap = styled.div`
  position: relative;
  top: -3rem;
  .wmk-media-video {
    max-height: 85vh !important;
  }
`;
const VideoHero = ({
  slides
}: {
  slides: (HomeSlideContentFields | LrhSlideContentFields)[];
}) => {
  //const { heroVideo, heroVideoPoster } = data;
  // const url =
  //   heroVideo && heroVideo.file && heroVideo.file.url
  //     ? heroVideo.file.url
  //     : null;
  // const poster =
  //   heroVideoPoster && heroVideoPoster.file && heroVideoPoster.file.url
  //     ? heroVideoPoster.file.url
  //     : null;

  // console.log("sliderAssets: ", sliderAssets)
  return (
    <Wrap>
      <HeroSlider assets={slides} />
      {/* <Video url={url} poster={poster} /> */}
      <QuickLinks />
    </Wrap>
  );
};

export default VideoHero;
