import React from "react";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../../vars/palette";

const StyledHeadlineLink = styled(WmkLink)`
  color: ${colors.hex("secondary")};
  transition: all 0.3s ease;
  text-decoration: none;
  &:hover {
    color: ${colors.hex("primary")};
    transition: all 0.3s ease;
  }
`;

const NewsFeedHeadline = ({
  children,
  path,
  big
}: {
  children: React.ReactNode;
  path: string;
  big?: boolean;
}) => {
  const Jsx = big
    ? ({ children }: { children: React.ReactNode }) => <h2>{children}</h2>
    : ({ children }: { children: React.ReactNode }) => <h5>{children}</h5>;
  return (
    <StyledHeadlineLink to={path}>
      <Jsx>{children}</Jsx>
    </StyledHeadlineLink>
  );
};

export default NewsFeedHeadline;
