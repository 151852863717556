import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { WmkLink } from "wmk-link";
import { colors } from "../../../../../vars/palette";
import { IconQuickLinksFields } from "../../../../../node/fragments/NodeIconQuickLinksFields";
import { Img, WmkImage } from "wmk-image";
import { constructPath } from "../../../../Blocks/BlockHash";

const squareSize = "10rem";

const SquareWrap = styled(Col)`
  &.square-col {
    transform: scale(1);
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.5s ease;
    }
    .icon-wrap {
      width: ${squareSize};
      height: ${squareSize};
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid
        ${({ alt }) => (alt ? colors.hex("accent") : colors.hex("secondary"))};
      padding: 0.5rem;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .title-wrap {
      background: ${({ alt }) =>
        alt ? colors.hex("accent") : colors.hex("secondary")};
      color: ${colors.hex("white")};
      text-transform: uppercase;
      text-align: center;
      width: ${squareSize};
      padding: 0.2rem 0 0 0;
      h5 {
        font-size: 0.9rem;
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .icon-wrap {
      width: 13rem !important;
      height: 13rem !important;
    }
    .title-wrap {
      width: 13rem !important;
      h5 {
        font-size: 1rem !important;
      }
    }
  }
`;

const QLSquares = () => {
  const data: QLSquaresQuery = useStaticQuery(query);
  const squares = data.menu.squares;
  return (
    <>
      {squares &&
        squares.map((square, i) => {
          const { icon, title, linkUrl, linkPage } = square;
          const fluid = new Img(icon);
          return (
            <SquareWrap
              sm={12}
              md={6}
              lg={3}
              className="square-col"
              alt={i === 0 ? "true" : undefined}
              key={title + i}>
              <>
                <WmkLink
                  to={linkUrl ? linkUrl : constructPath(linkPage)}
                  target={linkUrl ? "_blank" : null}>
                  <div className="icon-wrap">
                    <WmkImage image={fluid} />
                  </div>
                </WmkLink>
                <div className="title-wrap">
                  <h5>{square.title}</h5>
                </div>
              </>
            </SquareWrap>
          );
        })}
    </>
  );
};

export default QLSquares;

interface QLSquaresQuery {
  menu: { squares: IconQuickLinksFields[] };
}

const query = graphql`
  {
    menu: contentfulMenuContainer(title: { eq: "Quick Links: Squares" }) {
      squares: menuItems {
        ...NodeIconQuickLinksFields
      }
    }
  }
`;
