import { Img } from "wmk-image";
import { RichText } from "wmk-rich-text";
import { HomeSlideContentFields } from "../node/fragments/NodeGlobalFields";
import { HomePageFields, PageFields } from "../node/fragments/NodePageFields";

export interface PageBlocks {
  title: string;
  waveOptions: null | "Both" | "None" | "Top" | "Bottom";
  style: null | "Blue Background" | "Normal";
  showBottomDivider?: boolean;
  pages: Page[];
  hideTitle?: boolean;
  dividerPosition: "Center" | "Left" | "Right" | null;
  copy?: RichText;
}

export interface PageIconLinks {
  icon: Img;
  title: string;
  sortOrder: number;
  reference: Page;
  linkType: string[];
}

export class Page {
  titleBar: string;
  title: string;
  image: Img;
  parentPageSlug: string;
  subParentPageSlug: string;
  homeSlide: HomeSlideContentFields;
  iconLinks: PageIconLinks[];
  blocks: { main: PageBlocks[]; mainPt2: PageBlocks[]; secondary: PageBlocks[] };
  slug: string;
  meta: string;
  constructor(_node: PageFields | HomePageFields) {
    const node = { ..._node };

    this.titleBar = node.titleBar ? node.titleBar : node.title;
    this.title = node.title;
    this.image = new Img(node.heroImage);
    this.parentPageSlug = node.parentPageSlug;
    this.subParentPageSlug = node.subParentPageSlug;
    this.slug = node.slug;
    this.homeSlide = "homeSlide" in node ? node.homeSlide : undefined;
    this.meta = node.meta?.description ? node.meta.description : "";
    this.iconLinks = Array.isArray(node.iconLinks)
      ? node.iconLinks.map((l, i: number) => {
          return {
            icon: new Img(l.icon),
            title: l.title,
            sortOrder: l.sortOrder ? l.sortOrder : i,
            reference: new Page(l.reference),
            linkType: l.linkType
          };
        })
      : [];
    this.blocks = {
      main: Array.isArray(node.mainBlocks)
        ? node.mainBlocks.map((b) => {
            return {
              title: b.title,
              waveOptions: b.waveOptions,
              style: b.style,
              showBottomDivider: b.showBottomDivider,
              pages: Array.isArray(b.pages)
                ? b.pages.map((p) => new Page(p))
                : [],
              hideTitle: b.hideTitle,
              dividerPosition: b.dividerPosition,
              copy: new RichText(b.copy)
            };
          })
        : [],
        mainPt2: Array.isArray(node.mainBlocksSecondSection)
        ? node.mainBlocksSecondSection.map((b) => {
            return {
              title: b.title,
              waveOptions: b.waveOptions,
              style: b.style,
              showBottomDivider: b.showBottomDivider,
              pages: Array.isArray(b.pages)
                ? b.pages.map((p) => new Page(p))
                : [],
              hideTitle: b.hideTitle,
              dividerPosition: b.dividerPosition,
              copy: new RichText(b.copy)
            };
          })
        : [],
      secondary: Array.isArray(node.secondaryBlocks)
        ? node.secondaryBlocks.map((b) => {
            return {
              title: b.title,
              waveOptions: b.waveOptions,
              style: b.style,
              showBottomDivider: b.showBottomDivider,
              pages: Array.isArray(b.pages)
                ? b.pages.map((p) => new Page(p))
                : [],
              hideTitle: b.hideTitle,
              dividerPosition: b.dividerPosition,
              copy: new RichText(b.copy)
            };
          })
        : []
    };
  }
}

export class Pages {
  list: Page[];
  length: number;
  constructor(edges: { node: PageFields }[]) {
    const _edges = Array.isArray(edges) ? [...edges] : [];
    const pages = _edges.map((e) => new Page(e.node));
    this.list = pages;
    this.length = pages.length;
  }
}
