import React from "react";
import { Container, Row } from "react-bootstrap";
import styled from "styled-components";
import QLCircles from "./QLCircles";
import QLSquares from "./QLSquares";

const Wrap = styled(Container)`
  position: relative;
  z-index: 100;
  top: -6.5rem;
  .circle-col,
  .square-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .square-links-wrap {
    padding: 0 3rem;
    margin-top: 5rem;
    @media only screen and (max-width: 991px) {
      padding: 0;
      .square-col {
        margin-bottom: 3rem;
      }
    }
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
    @media only screen and (max-width: 590px) {
      padding: 0 3rem;
      margin-top: 3rem;
    }
    @media only screen and (max-width: 415px) {
      padding: 0 1rem;
      .square-col {
        margin-bottom: 1rem;
      }
    }
  }
`;

const QuickLinks = () => {
  /*
    The main component is lean and imports 
    two sub-components, representing
    the Circle and Square style of Quick Links

    
  */
  return (
    <Wrap>
      <Row className="circle-links-wrap">
        <QLCircles />
      </Row>
      <Row className="square-links-wrap">
        <QLSquares />
      </Row>
    </Wrap>
  );
};

export default QuickLinks;
