import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { WaveOptions } from "../../../../../node/fragments/NodePageFields";
import Bottom from "./WaveBottom";
import Top from "./WaveTop";

const Wave = ({
  wave,
  target
}: {
  wave: WaveOptions;
  target: "top" | "bottom";
}) => {
  const [contWidth, setContWidth] = useState(0);

  const handleResize = () => {
    const winWidth = window.innerWidth;
    const oldWidth = contWidth;
    setContWidth(winWidth ? winWidth : oldWidth);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    wave &&
    (wave.toLowerCase() === target || wave.toLowerCase() === "both") && (
      <Container fluid style={{ padding: 0, overflow: "hidden" }}>
        {target === "top" ? (
          <Top width={contWidth} />
        ) : (
          <Bottom width={contWidth} />
        )}
      </Container>
    )
  );
};

export default Wave;
