import React from "react";
import { StyledSidebar } from "./StyledNewsAndEvents";
import WidgetHeading from "../../../common/typography/WidgetHeading";
import { FaFacebookSquare } from "react-icons/fa";
import PowrFeed from "./PowrFeed/PowrFeed";
import { WmkLink } from "wmk-link";
import { graphql, useStaticQuery } from "gatsby";

const SocialIcon = ({
  to,
  children
}: {
  to: string;
  children: React.ReactNode;
}) => {
  return (
    <WmkLink to={to} target="blank">
      {children}
    </WmkLink>
  );
};

const Sidebar = () => {
  const { options } = useStaticQuery(query);
  const { socialLinks } = options;
  //console.log(socialLinks);

  return (
    <StyledSidebar>
      <WidgetHeading style={{ display: "flex", alignItems: "center" }}>
        Social Feed
        <SocialIcon to={socialLinks[1].url}>
          <FaFacebookSquare />
        </SocialIcon>
      </WidgetHeading>
      <PowrFeed id={"6359c87f_1597874569"} />
    </StyledSidebar>
  );
};

export default Sidebar;

const query = graphql`
  {
    options: contentfulGlobal(title: { eq: "UTRWD Footer Options" }) {
      title
      socialLinks {
        target
        url
        title
      }
    }
  }
`;
