import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ModernTags from "./ModernTags";
import NewsFeedHeadline from "./NewsFeedHeadline";
import { colors } from "../../../../../vars/palette";
import { Img, WmkImage } from "wmk-image";
import { RichText, RichTextReact } from "wmk-rich-text";
import { Tag } from "wmk-tag";
import { options } from "../../../../Blocks/richTextOptions";
import { WmkLink } from "wmk-link";

const Styled = {
  Thumbnail: styled(Col)``,
  Excerpt: styled.div`
    font-size: 0.95rem;
    a {
      color: ${colors.hex("secondary")};
    }
  `,
  Wrapper: styled(Row)`
    padding: 0.5rem 0;
    .text {
      padding-left: 1rem;
    }
    &.small {
      .text {
        padding-left: 0.5rem;
      }
    }
  `
};
export const StyledDate = styled(Col)`
  font-size: 0.9rem;
  font-weight: bold;
  color: ${colors.hex("primary")};
  margin-bottom: 1vh;
`;

const ModernExcerpt = ({
  title,
  date,
  image,
  tags,
  path,
  content,
  big
}: {
  title: string;
  date?: Date;
  image: Img;
  tags: Tag[];
  content: RichText;
  big?: boolean;
  path: string;
}) => {
  return (
    <Styled.Wrapper
      className={big ? "news-excerpt g-0" : "news-excerpt small g-0"}>
      <Col xs={big ? 12 : 4} sm={big ? 12 : 4} md={big ? 6 : 4}>
        <WmkLink to={path}>
          <WmkImage image={image} />
        </WmkLink>
      </Col>
      <Col xs={!big ? 8 : undefined} className="text">
        <Row className="flex-column">
          <Col>
            <NewsFeedHeadline path={path} big={big}>
              {title}
            </NewsFeedHeadline>
          </Col>
          <Col>
            <Row>
              <ModernTags tags={tags} />
              {date ? (
                <StyledDate xs="auto">{date.toLocaleDateString()}</StyledDate>
              ) : null}
            </Row>
          </Col>
          {big ? (
            <Col>
              <Styled.Excerpt>
                <RichTextReact content={content} options={options} />
              </Styled.Excerpt>
            </Col>
          ) : null}
        </Row>
      </Col>
    </Styled.Wrapper>
  );
};

export default ModernExcerpt;
