import React from "react";
import WidgetHeading from "../../../../common/typography/WidgetHeading";
import EventExcerpt from "./EventExcerpt";
import { Row } from "react-bootstrap";
import MoreLink from "../../../../common/MoreLink";
import { Event } from "../../../../../classes/Events";

export const getCurrentEvents = (events: Event[], limit?: number) => {
  const now = new Date();
  const current: Event[] = Array.isArray(events)
    ? events.reduce((c, event) => {
        if (event.date.getTime() > now.getTime()) {
          c.push(event);
        }
        return c;
      }, [])
    : [];
  return current.slice(0, limit ? limit : events.length);
};

const EventsFeed = ({ events, limit }: { events: Event[]; limit?: number }) => {
  const current = getCurrentEvents(events, limit);
  return (
    <Row className="flex-column">
      <WidgetHeading>Events</WidgetHeading>
      <Row>
        {current && current.length
          ? current.map((event, i) => {
              const { startDate, title, link } = event;
              return (
                <EventExcerpt key={title + i} startDate={startDate} link={link}>
                  {title}
                </EventExcerpt>
              );
            })
          : null}
      </Row>
      <MoreLink to="/newsroom/upcoming-events">View More</MoreLink>
    </Row>
  );
};

export default EventsFeed;
