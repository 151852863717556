import React from "react";
import styled from "styled-components";
import { colors } from "../../../vars/palette";

const TitleWrap = styled.div`
  color: ${colors.hex("white")};
  text-align: center;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  h1,
  h2 {
    border-bottom: 3px solid ${colors.hex("white")};
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const PageTitle = ({
  title,
  h2,
  style
}: {
  title: React.ReactNode;
  h2?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <TitleWrap className="page-title" style={style}>
      {!h2 ? <h1>{title}</h1> : <h2>{title}</h2>}
    </TitleWrap>
  );
};

export default PageTitle;
