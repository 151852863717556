import { EventsFields } from "../node/fragments/NodeEventsFields";

export class Event {
  title: string;
  startDate: Date;
  date: Date;
  showTime: boolean;
  link: string;
  linkAllText: boolean;
  constructor(_node: EventsFields) {
    const node = { ..._node };
    this.title = node.title;
    this.startDate = node.startDate ? new Date(node.startDate) : undefined;
    this.date = node.date ? new Date(node.date) : undefined;
    this.showTime = node.showTime ? true : false;
    this.link = node.link;
    this.linkAllText = node.linkAllText ? true : false;
  }
}

export class Events {
  list: Event[];
  length: number;
  constructor(edges: { node: EventsFields }[]) {
    const events = Array.isArray(edges)
      ? edges.map((e) => new Event(e.node))
      : [];
    this.list = events;
    this.length = events.length;
  }
}
