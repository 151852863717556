import React from "react";
import { WmkLink } from "wmk-link";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { colors } from "../../vars/palette";

const StyledMore = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    padding: 0.5rem 0.7rem;
    border: 2px solid ${colors.hex("accent")};
    color: ${colors.hex("primary")};
    transition: all 0.3s ease;
    text-decoration: none;
    &:hover {
      color: ${colors.hex("secondary")};
      transition: all 0.3s ease;
      border-color: ${colors.hex("primary")};
    }
  }
`;

const MoreLink = ({
  to,
  children,
  style
}: {
  to: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => {
  return (
    <StyledMore style={style}>
      <WmkLink to={to}>{children}</WmkLink>
    </StyledMore>
  );
};

export default MoreLink;
