import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ReactPlayer from "react-player";
import Theme, { buildSlug } from "../../../../vars/ThemeOptions";
import { WmkLink } from "wmk-link";
import { ContentfulImageQuery, Img, WmkImage } from "wmk-image";
import {
  HomeSlideContentFields,
  LrhSlideContentFields
} from "../../../../node/fragments/NodeGlobalFields";

const sliderHeight = "600px";

const WrapCont = styled<any>(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  height: ${sliderHeight};
  max-height: ${sliderHeight} !important;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  .slider-window {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .slides-row {
      position: absolute;
      display: flex;
      flex-wrap: nowrap;
      transition: left 0.5s ease;
      .slide {
        height: ${sliderHeight};
        .gatsby-image-wrapper {
          height: 100%;
          width: 100vw;
        }
        .video-react {
          height: ${sliderHeight};
          video {
            height: ${sliderHeight};
          }
        }
      }
    }
  }
  .cta-wrap {
    position: absolute;
    z-index: 20;
    color: white;
    width: 40%;
    margin-left: 21vw;
    h2 {
      text-transform: uppercase;
      font-weight: 600;
    }
    p {
      font-size: 0.9rem;
      width: 80%;
    }
    a {
      text-transform: uppercase;
      font-weight: 600;
      color: white;
      background: ${Theme.hex("primary")};
      padding: 4px 30px;
      border-radius: 10px;
      opacity: 1;
      transition: opacity 0.3s ease;
      text-decoration: none;
      &:hover {
        opacity: 0.9;
        transition: opacity 0.3s ease;
      }
    }
  }
  .controls-wrap {
    position: relative;
    bottom: 125px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      margin: 0 10px;
      border: none;
      border-radius: 50%;
      &.prevNext {
        background: white;
        &.prev {
          padding: 0 7px 4px 5px;
        }
        &.next {
          padding: 0 5px 4px 7px;
        }
        &.disabled {
          background: gray;
          &:hover {
            cursor: default;
          }
        }
      }
      &.dot {
        width: 15px;
        height: 15px;
        background: rgba(255, 255, 255, 0.5);
        transition: background 0.5s ease;
        &.active {
          background: ${Theme.hex("primary")};
          transition: background 0.5s ease;
          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
  .alert-wrap {
    height: 3rem;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 30%;
    button {
      position: absolute;
      z-index: 10;
      right: 0;
      background: ${Theme.hex("primary")};
      width: 4rem;
      height: 100%;
      border: none;
      border-left: 2px solid white;
      padding: 3px;
      img {
        width: 40px;
        height: 40px;
      }
    }
    .alert {
      z-index: 9;
      background: ${Theme.hex("primary")};
      color: white;
      width: auto;
      height: 100%;
      text-align: right;
      padding-right: 5rem;
      border-radius: 50px;
      p {
        margin-bottom: 0;
        font-size: 0.9rem;
      }
    }
  }
  .dark-overlay {
    width: 100%;
    height: ${sliderHeight};
    position: absolute;
    top: -28px;
    left: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1));
  }
  @media only screen and (max-width: 991px) {
    .cta-wrap {
      width: 90%;
      margin-left: 5%;
      p {
        width: 100%;
      }
    }
  }
`;

const HomeHeroSlider = ({
  assets
}: {
  assets: (HomeSlideContentFields | LrhSlideContentFields)[];
}) => {
  //console.log("assets for slider: ", assets)
  const [currSlide, setCurrSlide] = useState(0);
  const [slideWidth, setSlideWidth] = useState(0);
  const [slideHeight, setSlideHeight] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);

  const slideRef = useRef<HTMLDivElement>();
  const onResize = () => {
    setSlideWidth(slideRef.current.offsetWidth);
    setSlideHeight(slideRef.current.offsetHeight);
    //console.log("slideWidth: ",slideWidth)
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  useEffect(() => {
    const interval = setInterval(() => {
      updateCurrSlide(currSlide, "interval");
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });

  const updateCurrSlide = (
    currInd: number,
    prevNext: "prev" | "next" | "interval"
  ) => {
    prevNext === "next"
      ? currInd === assets.length - 1
        ? setCurrSlide(currInd)
        : setCurrSlide(currInd + 1)
      : prevNext === "prev"
      ? currInd === 0
        ? setCurrSlide(currInd)
        : setCurrSlide(currInd - 1)
      : prevNext === "interval"
      ? currInd === assets.length - 1
        ? setCurrSlide(0)
        : setCurrSlide(currInd + 1)
      : setCurrSlide(currInd);
    // console.log("currSlide: ", currSlide)
  };

  const alertData: {
    alertIcon: ContentfulImageQuery;
    copy: { showSlider?: boolean; siteTitle: string };
  } = useStaticQuery(graphql`
    query {
      alertIcon: contentfulAsset(title: { eq: "Alert Icon" }) {
        title
        file {
          url
          contentType
        }
      }
      copy: contentfulGlobal(title: { eq: "Alert Text" }) {
        showSlider
        siteTitle
      }
    }
  `);
  const currTitle = assets[currSlide]?.title;
  const slideTitle = Array.isArray(assets[currSlide].title.match(/swing/i)) ? (
    <h2>
      {currTitle.slice(0, currTitle.indexOf("... ") + 4)}
      <br />
      {currTitle.slice(currTitle.indexOf("... ") + 4, currTitle.length)}
    </h2>
  ) : (
    <h2>{currTitle}</h2>
  );
  return (
    <WrapCont fluid>
      <div className="slider-window" style={{ height: slideHeight }}>
        <div
          className="slides-row"
          style={{
            left: -(currSlide * slideWidth),
            transition: "left .5s ease"
          }}>
          {assets.map((asset, i) => {
            return asset?.slideBackground?.file?.contentType === "video/mp4" ? (
              <div key={"asset" + i} className="slide" ref={slideRef}>
                <ReactPlayer url={asset.slideBackground.file.url} />
              </div>
            ) : (
              <div ref={slideRef} key={"asset" + i} className="slide">
                <WmkImage image={new Img(asset?.slideBackground)} />
              </div>
            );
          })}
        </div>
      </div>
      <div className="cta-wrap">
        {slideTitle}
        <p>{assets[currSlide]?.subtitle?.subtitle}</p>
        {assets[currSlide]?.ctaUrl ? (
          <a
            href={assets[currSlide]?.ctaUrl}
            target="_blank"
            rel="noopener noreferrer">
            {assets[currSlide]?.ctaCopy}
          </a>
        ) : assets[currSlide]?.ctaPage ? (
          <WmkLink to={buildSlug(assets[currSlide]?.ctaPage)}>
            {assets[currSlide]?.ctaCopy}
            {
              //console.log(assets[currSlide])
            }
          </WmkLink>
        ) : null}
      </div>
      <div className="controls-wrap">
        <button
          className={
            currSlide === 0 ? "prevNext prev disabled" : "prevNext prev"
          }
          onClick={() => updateCurrSlide(currSlide, "prev")}
          aria-label={"Go Back"}>
          <IoIosArrowBack />
        </button>
        {assets.map((asset, i) => {
          const clsNm = currSlide === i ? "active" : "";
          return (
            <button
              key={"asset-btn" + i}
              className={`dot ${clsNm}`}
              onClick={() => setCurrSlide(i)}
              aria-label={"slide " + i}
            />
          );
        })}
        <button
          className={
            currSlide === assets.length - 1
              ? "prevNext next disabled"
              : "prevNext next"
          }
          onClick={() => updateCurrSlide(currSlide, "next")}
          aria-label="Next Slide">
          <IoIosArrowForward />
        </button>
      </div>
      {alertData.copy.showSlider && (
        <div className="alert-wrap">
          <button onClick={() => setAlertOpen(!alertOpen)}>
            <img
              src={alertData?.alertIcon?.file?.url}
              alt={alertData?.alertIcon?.title}
            />
          </button>
          <div
            className="alert"
            style={
              alertOpen
                ? { right: 0, transition: "right .3s ease" }
                : { right: "-100vw", transition: "right .5s ease" }
            }>
            <p>{alertData?.copy?.siteTitle}</p>
          </div>
        </div>
      )}
      <div className="dark-overlay" />
    </WrapCont>
  );
};

export default HomeHeroSlider;
