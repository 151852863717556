import React from "react";
import PageTitle from "./typography/PageTitle";
import styled from "styled-components";
import Wave from "../flexible/blocks/BlockBody/Wave/Wave";
import { colors } from "../../vars/palette";
import { Img, WmkImage } from "wmk-image";

const HeroWrap = styled.div`
  position: relative;
  max-height: 440px;
  width: 100%;
  border-bottom: 3px solid ${colors.hex("secondary")};
  .gatsby-image-wrapper {
    filter: brightness(0.85);
  }
  .page-title,
  .gatsby-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 766px) {
    .gatsby-image-wrapper {
      position: relative;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const PageHero = ({
  title,
  heroImage,
  titleBar,
  width,
  mobileImage
}: {
  title: string;
  heroImage?: Img;
  titleBar?: string;
  width: number;
  mobileImage?: Img;
}) => {
  const _title = titleBar ? titleBar : title;
  const hasHero = heroImage?.src;
  return (
    <>
      <HeroWrap
        style={{
          height:
            mobileImage && width < 767 ? "100%" : hasHero ? "60vh" : "35vh",
          background: hasHero ? "none" : colors.hex("secondary"),
          marginTop: mobileImage && width < 767 ? "80px" : 0
        }}>
        {mobileImage && width < 767 ? (
          <WmkImage image={mobileImage} />
          ) : (
          <WmkImage image={heroImage} />
        )}
        {_title && (
          <PageTitle
            title={Array.isArray(_title.match(/water less,/i)) ? "" : _title}
            style={{ marginTop: hasHero ? 0 : "5vh" }}
          />
        )}
      </HeroWrap>
      {!hasHero && <Wave wave="Bottom" target="bottom" />}
    </>
  );
};

export default PageHero;
