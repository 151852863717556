import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import DivBotLine from "./DivBotLine";
import DivBotIcon from "./DivBotIcon";
//import Theme from '../../../../../vars/ThemeOptions'

const DivivderWrap = styled(Container)`
  position: relative;
  padding-top: 3vh;
  display: flex;
  justify-content: center;
  .row,
  .col {
    padding: 0;
  }
  .div-bot-line {
    position: absolute;
    top: 62%;
  }
  .icon-col {
    padding: 0 4vw;
    &.center {
      display: flex;
      justify-content: center;
    }
    &.right {
      display: flex;
      justify-content: flex-end;
    }
    &.left {
      display: flex;
      justify-content: flex-start;
    }
  }
`;

export type DivPosition = null | "Center" | "Left" | "Right";

const DivBot = ({ pos }: { pos: DivPosition }) => {
  const [colWidth, setColWidth] = useState(0);

  const colRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const handleResize = () => {
      setColWidth(colRef.current.getBoundingClientRect().width);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [colWidth]);
  return (
    <DivivderWrap>
      <Row>
        <Col ref={colRef}>
          <DivBotLine width={colWidth} />
          <Col className={"icon-col " + pos.toLowerCase()}>
            <DivBotIcon />
          </Col>
        </Col>
      </Row>
    </DivivderWrap>
  );
};

export default DivBot;
