import React from "react";
import styled from "styled-components";
import { colors } from "../../../../../vars/palette";
import { Col } from "react-bootstrap";

const EVENT_HEIGHT = 30;

const Styled = {
  ShortDate: styled.div`
    display: flex;
    background-color: ${colors.hex("primary")};
    font-weight: 800;
    color: ${colors.hex("white")};
    align-items: center;
    span {
      padding: 0 0.75rem 0 0.5rem;
    }
  `,
  Skew: styled.div<{ color: string }>`
    display: inline-block;
    width: ${({ color }) => (color === colors.hex("primary") ? "8px" : "15px")};
    height: ${EVENT_HEIGHT}px;
    background-color: ${({ color }) => color};
    transform: skewX(-30deg);
    position: relative;
    left: 9px;
  `,
  LongDate: styled(Col)`
    color: ${colors.hex("accent")};
    font-size: 0.9rem;
  `
};

export const daysOfTheWeek = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun"
};

export const monthsOfTheYear = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec"
};

export const LongDate = ({ date }: { date: Date }) => {
  return <Styled.LongDate>{date.toDateString().slice(4, 15)}</Styled.LongDate>;
};

const ShortDate = ({ date }: { date: Date }) => {
  const dayNum = date.getDay() as 7 | 1 | 2 | 3 | 4 | 5 | 6;
  const monthNum = date.getMonth() as
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11;
  const now = new Date();
  const isWithinMonth =
    now.getMonth() === date.getMonth() &&
    now.getFullYear() === date.getFullYear();
  return (
    <Styled.ShortDate>
      <span>{`${
        isWithinMonth ? daysOfTheWeek[dayNum] : monthsOfTheYear[monthNum]
      } ${date.getDate()}`}</span>
      <Styled.Skew color={colors.hex("primary")} />
      <Styled.Skew color={colors.hex("tertiary")} />
    </Styled.ShortDate>
  );
};

export default ShortDate;
