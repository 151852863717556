import React from "react";

const DivBotIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="101.116"
      height="76.841"
      viewBox="0 0 101.116 76.841"
    >
      <g transform="translate(-682.001 -1307)">
        <path
          d="M10934-8515.3h61.451"
          transform="translate(-10232 9868.998)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.12h26"
          transform="translate(-10236 9882.817)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.12h10.116"
          transform="translate(-10203 9882.817)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.12h10.116"
          transform="translate(-10250 9868.817)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.12h10.116"
          transform="translate(-10163 9868.817)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.12h10.116"
          transform="translate(-10188 9889.817)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.12h21.043"
          transform="translate(-10184 9882.817)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.159h34.964"
          transform="translate(-10218.513 9875.998)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.157h15.538"
          transform="translate(-10240.087 9875.998)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.157h15.538"
          transform="translate(-10214.087 9889.998)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.157h15.538"
          transform="translate(-10204.087 9896.998)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <path
          d="M10934-8515.157h22.256"
          transform="translate(-10177.087 9875.997)"
          fill="none"
          stroke="#a7d6f0"
          strokeLinecap="round"
          strokeWidth="4"
        />
        <g transform="translate(692 1307)" fill="none">
          <path d="M41,0A41,41,0,0,1,82,41H0A41,41,0,0,1,41,0Z" stroke="none" />
          <path
            d="M 41 4 C 36.00327301025391 4 31.15812683105469 4.977714538574219 26.59915542602539 6.905998229980469 C 22.19369888305664 8.769355773925781 18.23635864257812 11.43774223327637 14.83705902099609 14.83705711364746 C 11.437744140625 18.23635673522949 8.769355773925781 22.19370079040527 6.905998229980469 26.59915733337402 C 5.496856689453125 29.93073463439941 4.5953369140625 33.41529846191406 4.212059020996094 37 L 77.78794097900391 37 C 77.4046630859375 33.41529846191406 76.50314331054688 29.93073463439941 75.09400177001953 26.59915733337402 C 73.23064422607422 22.19370079040527 70.562255859375 18.23635673522949 67.16294097900391 14.83705711364746 C 63.76364135742188 11.43774223327637 59.80629730224609 8.769355773925781 55.40084075927734 6.905998229980469 C 50.84187316894531 4.977714538574219 45.99672698974609 4 41 4 M 41 0 C 63.64366912841797 0 82 18.3563289642334 82 41 L 0 41 C 0 18.3563289642334 18.35632705688477 0 41 0 Z"
            stroke="none"
            fill="#a7d6f0"
          />
        </g>
      </g>
    </svg>
  );
};

export default DivBotIcon;
